@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.home {
  &__social {
    border-top: 0.5px solid #bfbfbf;
    padding: 2rem;

    &-container {
      height: auto;
      align-items: center;
      margin: 16px 0px;
      max-width: 1400px;

      @include respond(big-tab) {
        @include grid-row(2, auto);
      }

      &-row {
        margin: 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-row-gap: 40px;
        place-items: center;
      }
    }

    &-heading {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem;
      letter-spacing: 0em;
      text-align: center;
    }

    &-sub-heading {
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.75rem;
      letter-spacing: 0em;
      text-align: left;
    }

    &-icons {
      @include grid-column(3, auto);
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      grid-column-gap: 9px;
      margin-top: 1px;
    }

    &-box {
      @include grid-row(4, auto);
      grid-row-gap: 1px;
      place-items: center;
    }
  }
}
