@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.home {
  &__quotes {
    min-height: 36rem;
    @include flex-row;
    background-color: #fffbf2;
    box-shadow: inset 0px 24px 84px rgba(255, 246, 224, 0.25);
    position: relative;

    &-box {
      @include flex-column;

      &-icon {
        margin-bottom: 24px;
      }
      &-text {
        font-weight: 400;
        font-size: 38.8024px;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: center;
        width: 100%;
        white-space: nowrap;

        @include respond(tab) {
          font-size: 24px;
          line-height: 30px;
        }

        @include respond(phone) {
          font-size: 18px;
          line-height: 20px;
        }

        &__container {
          height: 70px;
          overflow: hidden;

          @include respond(tab) {
            height: 30px;
          }

          @include respond(phone) {
            height: 20px;
          }
        }
      }
    }

    &-container {
      height: 100%;
      width: 100%;
      @include grid-row(1, auto);
      grid-row-gap: 80px;
      @include grid-center;
    }

    &-left-flower {
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      @include z-index(overlay);

      @include respond(big-tab) {
        transform: scale(0.8);
        transform-origin: bottom left;
      }

      @include respond(phone) {
        transform: scale(0.5);
        transform-origin: bottom left;
      }
    }
    &-right-flower {
      position: absolute;
      bottom: -0.5rem;
      right: 0;
      @include z-index(overlay);

      @include respond(big-tab) {
        transform: scale(0.8);
        transform-origin: bottom right;
      }

      @include respond(phone) {
        transform: scale(0.5);
        transform-origin: bottom right;
      }
    }
  }
}
