@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.backstoryModal {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 5px;
    border-radius: 5px;
    max-height: 85vh;
    overflow-y: auto;
    padding: 40px;

    @include respond(phone) {
      padding: 48px 32px;
      max-height: auto;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;
      @include column;
      gap: 0.75rem;
      @include z-index(fill-page);
    }

    &__top {
      @include column;
      gap: 0.75rem;
      margin-bottom: 40px;

      h1 {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__article {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.75rem;
      letter-spacing: 0em;
    }

    &__scroll {
      width: 100%;
      height: 400px;
      overflow: hidden;

      @include respond(phone) {
        height: 80vh;
      }

      &__bar {
        width: 8px;
        background: #fff8e6;
        border-radius: 30px;
        height: 100%;
      }

      &__thumb {
        width: 8px;
        height: 26px;
        background: #ffe299;
        border-radius: 30px;
      }
    }

    &__bottom {
      @include column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }
  }
}
