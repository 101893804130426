@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.home {
  &__event-types {
    // @include grid-column(3, 1fr);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 85vw;

    @include respond(big-tab) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
    }

    &-icon {
      margin-bottom: 8px;
    }

    &-heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 24px;
      font-family: $Tiempos;
    }

    &-date {
      margin-bottom: 24px;
      font-family: $Tiempos;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
    }

    &-time {
      margin-bottom: 24px;
      font-family: $Tiempos;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
    }

    &-box {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      width: 100%;
      padding: 0.75rem 0.75rem;
      @include flex-column;
      justify-content: space-between;

      @include respond(big-tab) {
        border-right: none;
        padding-bottom: 40px;
        margin-top: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &:first-child {
          margin-top: 0px;
        }
        &:last-child {
          border: none;
          padding: 0;
        }
      }

      &:last-child {
        border: none;
      }

      & button {
        width: fit-content !important;
        display: flex;
        gap: 4px;
      }
    }

    &-container {
      width: 100%;
      @include flex-row;
      height: 100%;
      padding: 2rem;

      @include respond(big-tab) {
        height: auto;
        padding: 80px 0px;
      }
    }
  }
}
