@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.home {
  &__carousel {
    width: 80vw;
    height: 85vh;

    @include respond(phone) {
      width: 100vw;
      height: 100vh;
    }

    &-image {
      width: 100%;
      height: 100%;
    }
  }

  &__gallery-section {
    padding: 80px 0px 63px 0px;

    &-image {
      position: relative;
      height: 100%;
      width: 100%;

      & img {
        object-fit: cover !important;
      }

      &-outside {
        width: 90%;
        height: 90%;
        padding: 40px;
        max-width: 1000px;
        position: relative;
        max-height: 1000px;
        margin: 0 auto;
        border-radius: 4px;
        background: $col-white;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 1rem;

        @include respond(tab) {
          padding: 5.5rem 1.5rem;
          width: 100%;
          height: 100%;
        }

        & img {
          object-fit: auto;
        }
      }

      &-big {
        position: relative;
        width: 100%;
        height: 100%;

        @include respond(phone) {
          width: 100%;
          height: 100%;
        }

        & img {
          object-fit: auto;
        }

        &__download {
          float: right;
          background: #f6b100;
          width: fit-content;
          padding: 12px 16px;
          @include flex-row;
          align-items: center;
          border-radius: 4px;
        }
      }
    }

    &-container {
      margin-top: 37px;

      display: grid;
      padding: 0 1rem;
      max-width: 1500px;
      margin: 0 auto;

      &-inner {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        flex-wrap: wrap;

        & > button {
          width: 20rem !important;
          height: 20rem !important;
        }

        @include respond(phone) {
          & > button {
            height: 30rem !important;
            width: 100% !important;
          }
        }
      }

      &-button {
        @apply my-8 mx-auto max-w-max;
      }
    }

    &-header {
      width: 100%;
      @include flex-column;

      &-title {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.8125rem;
        letter-spacing: 0em;
        text-align: center;
        font-family: $Tiempos;
      }
      &-icon {
        margin-bottom: 8px;
      }
    }
  }
}
