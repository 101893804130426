@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.home {
  &__knowthecouple {
    width: 100%;
    background: #f4fdfd;
    @include row;
    justify-content: center;
    position: relative;

    // @media only screen and (max-width: 80rem) {
    //   min-height: 150vh;
    // }
    // @media only screen and (min-width: 80.1rem) {
    //   min-height: 110vh;
    // }

    @include respond(big-tab) {
      background-color: #fdf4fc;
      box-shadow: inset 0px 24px 84px rgba(255, 246, 224, 0.25);
    }

    @include respond(phone) {
      min-height: auto;
      background-color: #fdf4fc;
      box-shadow: inset 0px 24px 84px rgba(255, 246, 224, 0.25);
    }

    &--inner {
      @include column;
      padding: 8rem 0 8rem 0;
      width: 100%;
      height: 100%;
      background: #f4fdfd;
    }

    &-left-logo {
      position: absolute;
      bottom: 0;
      left: 0;
      @include z-index(overlay);
    }

    &-right-logo {
      position: absolute;
      bottom: 0;
      right: 0;
      @include z-index(overlay);
    }

    &--title {
      color: $col-black;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem;
      letter-spacing: 0em;
      text-align: center;
      @include flex-row;
      width: 100%;
      height: 110px;
      margin-bottom: 100px;
      padding-top: 100px;
    }
    &--container {
      @include row;
      justify-content: center;
      height: auto;
      width: 100%;

      // &-inner {
      // display: grid;
      // grid-template-rows: repeat(2, auto);
      // grid-row-gap: 70px;
      // place-items: center;
      // }

      &-deck {
        height: 100%;
        width: 100%;

        &__view-more {
          color: $col-primary !important;
          font-weight: 500 !important;
          font-size: 1rem !important;

          @include transition;

          &:hover {
            filter: brightness(0.8);
          }

          @include respond(phone) {
            margin: 0.75rem 0;
          }
        }
      }

      @include respond(tab) {
        grid-template-columns: minmax(10px, 3fr) 3fr;
      }

      @include respond(phone) {
        grid-template-columns: 1fr;
      }

      .card {
        display: grid;
        grid-template-columns: auto 450px;
        grid-column-gap: 65px;
        place-items: center;

        @include respond(big-tab) {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, auto);
          margin: 0px 20px;
          grid-row-gap: 40px;
        }

        &__image {
          &-husband {
            height: 450px;
            position: relative;
            width: 270px;

            @include respond(big-tab) {
              width: 154px;
              height: 154px;
            }
          }
        }

        p {
          font-weight: 700;
          font-size: 1.5rem;
          margin: 1rem 0;
        }
        article {
          text-align: justify;
          display: -webkit-box;
          -webkit-line-clamp: 12;
          line-clamp: 12;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 300;
          line-height: 1.75rem;
          letter-spacing: 0em;
          text-align: left;
          height: 340px;
          overflow: hidden;
          text-overflow: ellipsis;

          @include respond(big-tab) {
            height: 245px;
            display: -webkit-box;
            -webkit-line-clamp: 9;
            line-clamp: 9;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
