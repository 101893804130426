@use "@styles/abstracts/variables.scss" as *;
@use "@styles/abstracts/mixins.scss" as *;

.floater {
  @include flex-row;
  width: 100%;

  &-icon {
    @include flex-column;
    width: fit-content;
    &-box {
      height: 35px;
      @include flex-row;
      color: #f6b100;
    }

    @include respond(big-tab) {
      margin-right: 40px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &-paragraph {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    text-align: center;
    margin-top: 18px;
    text-transform: capitalize;
    white-space: nowrap;
    height: 16px;
    display: flex;
    align-items: center;

    @include respond(tab) {
      margin-top: 8px;
      font-size: 10px;
      line-height: 12px;
    }

    // @include respond(phone) {
    // 	display: none;
    // }
  }

  &-container {
    position: fixed;
    bottom: 1%;

    z-index: 10000000;

    width: auto;
    max-width: 670px;
    margin: 24px 0px;
    background-color: #fff;
    box-shadow: 0.2px 0.4px 0.5px hsl(0deg 0% 63% / 0.36),
      0.5px 1.3px 1.6px -0.8px hsl(0deg 0% 63% / 0.36),
      1.3px 3.4px 4.1px -1.7px hsl(0deg 0% 63% / 0.36),
      3.1px 8.2px 9.9px -2.5px hsl(0deg 0% 63% / 0.36);

    @include border-radius(10px);
    padding: 24px 80px;
    @include row;
    justify-content: space-around;
    align-items: center;
    gap: 4rem;

    @include respond(tab) {
      @include row;
      justif-content: flex-start;
      overflow-x: scroll;
      max-width: 90vw;

      // height: 128px;
      padding: 12px 32px;
      width: fit-content;
      gap: 1rem;
      bottom: 1%;

      svg {
        transform: scale(0.8);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include respond(phone) {
      gap: 1rem;
    }
  }
}
