@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.home {
  &__marquee {
    padding: 3rem 0;
    @include column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    background: #fffbf2;
  }

  &__special-note {
    padding: 125px 0px 80px 0px;
    background-color: #fffbf2;
    width: 100%;
    height: 100%;
    @include flex-column;

    @include respond(big-tab) {
      width: 100vw;
    }

    &__btn {
      font-size: 16px;
      width: 100%;
      color: $col-black;
      padding: 12px 32px;
      background: transparent;
      border: 1px solid #edac06;
      border-radius: 4px;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-black;
        font-family: $Tiempos;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
      &:disabled,
      &[disabled] {
        background: #cccccc;
        border: 1px solid #cccccc;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }

        &:hover,
        &:focus-within {
          background: #cccccc;
          border: 1px solid #cccccc;

          & span {
            color: $col-white;
          }
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-2;
      }
    }

    &-text {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
      letter-spacing: 0em;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-button {
      height: 43px;
      width: fit-content;
      @include border-radius(2px);
      padding: 12px 24px;
      background-color: #edac06;
      font-family: $Tiempos;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $col-black;

      &-container {
        width: 100%;
        height: 100%;
        @include flex-row;
        margin-top: 100px;
      }
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(auto-fit, auto);
      grid-column-gap: 85px;
      grid-row-gap: 73px;

      @include respond(big-tab) {
        width: 100%;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-box {
      height: 224px;
      width: 284px;
      margin-left: 2rem;
      padding: 24px 16px 24px 16px;
      background-color: $col-white;
      box-shadow: 0px 4px 4px rgba(106, 105, 105, 0.25);
      @include column;
      justify-self: center;
      align-self: center;
      overflow: hidden;

      &:nth-child(2) {
        margin-top: 100px;
        margin-left: 50px;
        margin-right: 50px;
      }

      &:nth-child(4) {
        margin-left: 70px;
      }

      &:nth-child(6) {
        margin-right: 70px;
      }

      &-circle {
        &-container {
          width: 100%;
          height: 50px;
          @include flex-row;
        }
        height: 18px;
        width: 18px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          #d70000 0%,
          #b70000 100%
        );
        @include border-radius(50%);
      }
    }
  }
}
