@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.messageModal {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    max-height: 85vh;
    overflow-y: auto;
    padding: 40px;

    @include respond(phone) {
      padding: 48px 32px;
      max-height: auto;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;
      @include column;
      gap: 0.75rem;
      @include z-index(fill-page);
    }

    &__top {
      @include column;
      gap: 0.75rem;
      margin-bottom: 40px;

      h1 {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__sub {
      display: flex;
      height: fit-content;
      margin-bottom: 0.75rem;
    }

    &-form {
      &_container {
        margin-bottom: 2rem;
        @include grid-row(auto-fill, auto);
        grid-row-gap: 0.5rem;

        & textarea {
          -webkit-touch-callout: text;
          -webkit-user-select: text;
          user-select: text;
          resize: none;
        }
      }

      &__max {
        display: flex;
        justify-content: flex-end;
        font-size: 0.75rem;
        font-style: normal;
      }

      &__errors {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1875rem;
        text-align: left;
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: $col-fail;
      }

      &__field {
        position: relative;
        padding: 0.8rem 1.5rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-right: 3rem;
        border: 1px solid $col-secondary;
        background-color: $col-secondary-2;
        width: 100%;

        @include border-radius(2px);
        @include flex-space-between;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.375rem;
          color: $col-secondary-9;
        }
      }
      &-btnContainer {
        display: flex;
        div {
          margin-left: auto;
          button {
            width: auto;
          }
        }
      }
    }
  }
}
